import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { Table, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { getNetworkName } from './helpers';
import PaginateControl from './PaginateControl';
import { toPrettyTime } from '../../helpers';

const PAGE_SIZE = 25;

const ReconciliationJobItems = ({ id, mid, getReconcileJobItems }) => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rows, setRows] = useState([]);
  const [job, setJob] = useState({});
  const [merchantName, setMerchantName] = useState('');

  useEffect(() => {
    const fetchItemData = async () => {
      setErrorMessage('');
      setIsLoading(true);
      try {
        const { Items, Job } = await getReconcileJobItems({ id, nmxid: mid, page, page_size: PAGE_SIZE });
        setRows(Items);
        setJob(Job);
        if (Items.length > 0) {
          setMerchantName(Items[0].Merchant);
        }
      } catch (e) {
        console.log(e);
        setErrorMessage(
          e.error?.ErrorMessage || e.message || 'An unknown error has occurred while fetching job items.',
        );
      }
      setIsLoading(false);
    };
    fetchItemData();
  }, [page]);

  if (errorMessage) {
    return (
      <div>
        <p>{errorMessage}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <h3>{getNetworkName(job.NetworkId)}</h3>
        <div>
          <b>{toPrettyTime(job.Date)}</b>
        </div>
        <div>
          <b>{merchantName}</b>
        </div>
        <div>
          <b>Status:</b> {job.Status}
        </div>
        <div>
          <b>Retries: </b>
          {job.RetryCount}
        </div>
      </div>
      <hr />
      <Form className="get-reconcile-job-items" autoComplete="off">
        <Form.Group style={{ justifyContent: 'space-between' }}>
          <PaginateControl
            page={page}
            prevDisabled={page === 1 || isLoading}
            nextDisabled={isLoading}
            onPrev={() => setPage(pageNo => pageNo - 1)}
            onNext={() => setPage(pageNo => pageNo + 1)}
          />
        </Form.Group>
      </Form>
      <Table>
        <thead style={{ fontWeight: 'bold', fontSize: 18, backgroundColor: '#e8e5e3' }}>
          <tr>
            <th>Sale Amount</th>
            <th>Commission Amount</th>
            <th>Status</th>
            <th>Unique ID</th>
          </tr>
        </thead>
        <tbody>
          {rows.map(({ UniqueID, Status, SaleAmount, CommissionAmount }) => {
            return (
              <tr key={UniqueID}>
                <td>{SaleAmount}</td>
                <td>{CommissionAmount}</td>
                <td>{Status}</td>
                <td>{UniqueID}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default ReconciliationJobItems;
