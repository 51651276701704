import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Loader, Dimmer, Segment, Form, Button } from 'semantic-ui-react';
import StatsChart from 'components/SalesDashboard/StatsChart';
import StatsTable from 'components/SalesDashboard/StatsTable';
import { timeZoneOptions, groupByOptions, timeLabel } from './Constants';
import './SalesDashboard.css';

const getSearchParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const startDateParam = urlParams.get('start_date');
  const endDateParam = urlParams.get('end_date');
  const tzOffsetParam = urlParams.get('timezone_offset');
  let groupByParam = urlParams.get('group_by');
  let filterAppIdParam = urlParams.get('filter_app_id');
  let filterMerchantIdParam = urlParams.get('filter_merchant_id');
  let filterMerchantIncludeParam = urlParams.get('filter_merchant_include');
  let filterNetworkIdParam = urlParams.get('filter_network_id');

  // transformations for query parameters associated with the new sales dash query param format
  const applicationIds = urlParams.getAll('application_id');
  if (applicationIds.length) {
    filterAppIdParam = applicationIds;
  }

  const networkIds = urlParams.getAll('network_id');
  if (networkIds.length) {
    filterNetworkIdParam = networkIds;
  }

  const merchantIds = urlParams.getAll('merchant_id');
  if (merchantIds.length) {
    filterMerchantIdParam = merchantIds;
    filterMerchantIncludeParam = 'true';
  }

  const notMerchantIds = urlParams.getAll('not_merchant_id');
  if (notMerchantIds.length) {
    filterMerchantIdParam = notMerchantIds;
    filterMerchantIncludeParam = 'false';
  }
  if (groupByParam?.endsWith('_id')) {
    groupByParam = groupByParam.slice(0, -3);
  } else {
    const dateTrunc = urlParams.get('date_trunc');
    if (dateTrunc) {
      if (dateTrunc === 'quarter') {
        // old sales dash does not have the option to group by quarter, month is closest facsimile
        groupByParam = 'month';
      } else {
        groupByParam = dateTrunc;
      }
    }
  }
  // end query parameter transformations

  return {
    startDateParam,
    endDateParam,
    groupByParam,
    tzOffsetParam,
    filterAppIdParam,
    filterMerchantIdParam,
    filterMerchantIncludeParam,
    filterNetworkIdParam,
  };
};

const SalesDashboard = ({ getDashboardStats, getFilterOptions, getSalesDashboardFilterItems }) => {
  const history = useHistory();
  // Dropdown Options
  const [applicationOptions, setApplicationOptions] = useState([]);
  const [merchantOptions, setMerchantOptions] = useState([]);
  const [networkOptions, setNetworkOptions] = useState([]);
  // Sales Dashboard Application Names
  const [merchantDashboardNames, setMerchantDashboardNames] = useState({});
  const [applicationDashboardNames, setApplicationDashboardNames] = useState({});
  const [networkDashboardNames, setNetworkDashboardNames] = useState({});
  const [isLoadingMerchantNames, setIsLoadingMerchantNames] = useState(true);
  const [dashboardStats, setDashboardStats] = useState(null);
  const [formState, setFormState] = useState({
    startDate: '',
    endDate: '',
    groupBy: '',
    tzOffset: '',
    filterAppId: [],
    filterMerchantId: [],
    filterMerchantInclude: '',
    filterNetworkId: [],
  });
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [groupLabel, setGroupLabel] = useState('');
  const [error, setError] = useState(false);
  const [chartMetric, setChartMetric] = useState({
    key: 'GrossCommissionAmount',
    text: 'Gross Commissions',
    value: 'GrossCommissionAmount',
    unit: '$',
  });
  const [chartType, setChartType] = useState('area');

  const {
    startDate,
    endDate,
    groupBy,
    tzOffset,
    filterAppId,
    filterMerchantId,
    filterMerchantInclude,
    filterNetworkId,
  } = formState;

  const getDate = (dayOffset, timezone = 'America/Los_Angeles') => {
    let currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + dayOffset);

    let currentDay, currentMonth, currentYear;
    if (timezone === 'UTC') {
      currentDay = currentDate.getUTCDate();
      currentMonth = currentDate.getUTCMonth(); // January is 0, not 1
      currentYear = currentDate.getUTCFullYear();
    } else {
      currentDay = currentDate.getDate();
      currentMonth = currentDate.getMonth(); // January is 0, not 1
      currentYear = currentDate.getFullYear();
    }

    //if month or day is a single digit, pad it w a 0 in front of it to keep the YYYY-MM-DD convention
    return `${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}-${currentDay.toString().padStart(2, '0')}`;
  };

  const fetchSalesDashboardNames = useCallback(
    async category => {
      const acceptableCategories = new Set(['merchant', 'network', 'application']);
      const includedApplications = [
        'client',
        'wf keyboard',
        'wildlink apps',
        'wildlink chrome extension',
        'wildfire demo',
      ];

      if (!acceptableCategories.has(category)) {
        return;
      }

      // Generates Sales Dashboard Names
      try {
        let salesDashboardNames = await getSalesDashboardFilterItems(category);
        if (category === 'application') {
          // Pre-process for applications to have only client or WF/Wildlink names
          salesDashboardNames = salesDashboardNames.filter(app => {
            return new RegExp(includedApplications.join('|')).test(app.Name.toLowerCase());
          });
        }

        // Generates Dropdown Options
        let dropdownOptions;
        if (category === 'merchant') {
          const filterItems = await getFilterOptions(category);
          // if category is merchant, don't include the id and keep the same order (highest to lowest sale amount)
          dropdownOptions = filterItems.map((filter, index) => {
            return {
              key: filter.ID,
              text: `${index + 1}. ${filter.Name}`,
              name: filter.Name,
              value: filter.ID,
            };
          });
        } else {
          dropdownOptions = salesDashboardNames
            .map(application => {
              return {
                key: application.ID,
                text: `${application.ID} - ${application.Name}`,
                name: application.Name,
                value: application.ID,
              };
            })
            .sort((a, b) => a.value - b.value);
        }

        const salesDashboardNamesHashMap = salesDashboardNames.reduce(
          (obj, item) => Object.assign(obj, { [item.ID]: item.Name }),
          {},
        );

        switch (category) {
          case 'merchant':
            setMerchantDashboardNames(salesDashboardNamesHashMap);
            setMerchantOptions(dropdownOptions);
            setIsLoadingMerchantNames(false);
            break;
          case 'application':
            setApplicationDashboardNames(salesDashboardNamesHashMap);
            setApplicationOptions(dropdownOptions);
            break;
          case 'network':
            setNetworkDashboardNames(salesDashboardNamesHashMap);
            setNetworkOptions(dropdownOptions);
            break;
          default:
            console.warn('Unknown category provided in fetchSalesDashboardNames');
        }
      } catch (err) {
        console.error(err);
      }
    },
    [getFilterOptions, getSalesDashboardFilterItems],
  );

  // nameFromStorage wraps returning the name from the localStorage maps generated in fetchFilterItems
  const nameFromStorage = (tableLabel, ID) => {
    switch (tableLabel) {
      case 'application':
        return applicationDashboardNames[ID];
      case 'network':
        return networkDashboardNames[ID];
      case 'merchant':
        return merchantDashboardNames[ID];
      default:
        console.warn('Unknown category provided in nameFromStorage');
    }
  };

  const formatFilterNumbers = string => {
    return string?.split(',').map(item => Number(item));
  };

  const formatURI = useCallback(() => {
    let searchURI = `?start_date=${startDate}`;

    const filterAppString = filterAppId.join().length ? filterAppId.join() : '';
    const filterMerchantString = filterMerchantId.join().length ? filterMerchantId.join() : '';
    const filterNetworkString = filterNetworkId.join().length ? filterNetworkId.join() : '';

    const uriValues = [
      { name: 'end_date', value: endDate },
      { name: 'group_by', value: groupBy },
      { name: 'timezone_offset', value: tzOffset },
      {
        name: 'filter_app_id',
        value: filterAppString,
      },
      {
        name: 'filter_merchant_id',
        value: filterMerchantString,
      },
      {
        name: 'filter_merchant_include',
        value: filterMerchantInclude,
      },
      {
        name: 'filter_network_id',
        value: filterNetworkString,
      },
    ];

    uriValues.forEach(i => {
      if (i.value !== '') {
        searchURI += `&${i.name}=${i.value}`;
      }
    });
    return searchURI;
  }, [endDate, filterAppId, filterMerchantId, filterMerchantInclude, filterNetworkId, groupBy, startDate, tzOffset]);

  const updateDashboardStats = useCallback(
    async (searchURI, groupBy) => {
      try {
        const res = await getDashboardStats(searchURI);
        setGroupLabel(groupBy);
        setError(false);
        setDashboardStats(res);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setError(error);
        setIsLoading(false);
      }
    },
    [getDashboardStats],
  );

  const fetchDashboardStats = useCallback(async () => {
    const {
      startDateParam,
      endDateParam,
      groupByParam,
      tzOffsetParam,
      filterAppIdParam,
      filterMerchantIdParam,
      filterMerchantIncludeParam,
      filterNetworkIdParam,
    } = getSearchParams();

    setFormState(prevFormState => ({
      ...prevFormState,
      startDate: startDateParam,
      endDate: endDateParam || getDate(0), //if no end date specified in the url, use today
      groupBy: groupByParam,
      tzOffset: tzOffsetParam,
      filterAppId: formatFilterNumbers(filterAppIdParam) || [], //if no filterAppId specified in the url, set default to all applications
      filterMerchantId: formatFilterNumbers(filterMerchantIdParam) || [], //if no filterMerchantId specified in the url, set to all merchants
      filterMerchantInclude: filterMerchantIncludeParam || 'true', //if no filterMerchantInclude specified in the url, set to include
      filterNetworkId: formatFilterNumbers(filterNetworkIdParam) || [], //if no filterNetworkId specified in the url, set to all networks
    }));
    setIsReady(true);
  }, []);

  const handleChange = (event, data) => {
    const { name, value } = data;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const searchURI = formatURI();
    setIsLoading(true);
    history.push({
      pathname: '/sales-dashboard',
      search: searchURI,
    });
    updateDashboardStats(searchURI, groupBy);
  };

  const toggleInclude = () => {
    setFormState({
      ...formState,
      filterMerchantInclude: 'true',
    });
  };

  const toggleExclude = () => {
    setFormState({
      ...formState,
      filterMerchantInclude: 'false',
    });
  };

  const getProcessedTimestamp = () => {
    const r = JSON.parse(dashboardStats.ProcessedTimestamp);
    const newDate = new Date(r.Processed).toLocaleString('en-US', { timeZoneName: 'short' });
    return newDate;
  };

  const isDashboardNamesLoaded = useMemo(() => {
    const noDashboardNamesLoaded =
      _.isEmpty(applicationDashboardNames) && _.isEmpty(merchantDashboardNames) && _.isEmpty(networkDashboardNames);

    if (noDashboardNamesLoaded) {
      return false;
    }

    const { groupBy } = formState;

    switch (groupBy) {
      case 'network':
        return !_.isEmpty(networkDashboardNames);
      case 'merchant':
        return !isLoadingMerchantNames;
      default:
        return !_.isEmpty(applicationDashboardNames);
    }
  }, [isLoadingMerchantNames, applicationDashboardNames, merchantDashboardNames, networkDashboardNames, formState]);

  useEffect(() => {
    const {
      startDateParam,
      endDateParam,
      groupByParam,
      tzOffsetParam,
      filterAppIdParam,
      filterMerchantIdParam,
      filterMerchantIncludeParam,
      filterNetworkIdParam,
    } = getSearchParams();
    // force initial view w/o query params to get the last 10 days in PST
    let searchURI = `?start_date=${startDateParam || '10daysago'}`;

    if (endDateParam) {
      searchURI += `&end_date=${endDateParam}`;
    }

    searchURI += `&group_by=${groupByParam || 'day'}`;
    searchURI += `&timezone_offset=${tzOffsetParam || 'America/Los_Angeles'}`;

    if (filterAppIdParam) {
      searchURI += `&filter_app_id=${filterAppIdParam}`;
    }

    if (filterMerchantIdParam) {
      searchURI += `&filter_merchant_id=${filterMerchantIdParam}`;
    }

    if (filterMerchantIncludeParam) {
      searchURI += `&filter_merchant_include=${filterMerchantIncludeParam}`;
    }

    if (filterNetworkIdParam) {
      searchURI += `&filter_network_id=${filterNetworkIdParam}`;
    }
    const currentPath = `${history.location.pathname}${history.location.search}`;
    const newPath = `/sales-dashboard${searchURI}`;
    if (currentPath !== newPath) {
      history.push({
        pathname: '/sales-dashboard',
        search: searchURI,
      });
    }
  }, []);

  useEffect(() => {
    if (!isReady) {
      fetchDashboardStats();
    } else {
      updateDashboardStats(formatURI(), groupBy);
    }
  }, [fetchDashboardStats, isReady, updateDashboardStats]);

  useEffect(() => {
    fetchSalesDashboardNames('application');
    fetchSalesDashboardNames('merchant');
    fetchSalesDashboardNames('network');
  }, [fetchSalesDashboardNames]);

  return (
    <div className="sales-dashboard">
      <nav className="filter-bar">
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              min="2017-07-01"
              max={getDate(0, tzOffset)}
              label="Start Date"
              name="startDate"
              type="date"
              value={
                //if the startdate is a "daysago" value, calculate the actual date for the UI to display
                startDate.includes('daysago') ? getDate(-startDate.split('daysago')[0]) : startDate
              }
              onChange={handleChange}
            />
            <Form.Input
              min="2017-07-01"
              max={getDate(0, tzOffset)}
              label="End Date"
              name="endDate"
              type="date"
              step="1"
              value={endDate}
              onChange={handleChange}
            />
            <Form.Select
              label="Group By"
              name="groupBy"
              options={groupByOptions}
              value={groupBy}
              onChange={handleChange}
            />

            <Form.Select
              label="Time Zone"
              name="tzOffset"
              options={timeZoneOptions}
              value={tzOffset}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Dropdown
              fluid
              multiple
              selection
              clearable
              search
              name="filterAppId"
              label="Application Filter"
              placeholder="All Applications"
              options={applicationOptions}
              value={filterAppId}
              onChange={handleChange}
            />
            <Form.Field>
              <span style={{ fontWeight: 'bold' }}>Merchant Filter</span>
              <div style={{ float: 'right' }}>
                <Button
                  attached="left"
                  onClick={toggleInclude}
                  className={filterMerchantInclude === 'true' ? 'green' : 'gray'}
                  size="mini"
                >
                  Include
                </Button>
                <Button
                  attached="right"
                  onClick={toggleExclude}
                  className={filterMerchantInclude === 'true' ? 'gray' : 'red'}
                  size="mini"
                >
                  Exclude
                </Button>
              </div>
              <Form.Dropdown
                fluid
                multiple
                selection
                clearable
                search
                name="filterMerchantId"
                placeholder="All Merchants"
                options={merchantOptions}
                value={filterMerchantId}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Dropdown
              fluid
              multiple
              selection
              clearable
              search
              name="filterNetworkId"
              label="Network Filter"
              placeholder="All Networks"
              options={networkOptions}
              value={filterNetworkId}
              onChange={handleChange}
            />
            <div id="submit-button-container">
              <Button type="submit" id="submit-button">
                Submit
              </Button>
            </div>
          </Form.Group>
        </Form>
      </nav>
      {error ? (
        <>
          <div>An error occurred:</div>
          <div>{error.message}</div>
        </>
      ) : isLoading || !dashboardStats || !isDashboardNamesLoaded ? (
        <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        </Segment>
      ) : (
        <>
          {dashboardStats.GraphCommissions && (
            <StatsChart
              chartStats={dashboardStats.GraphCommissions}
              timeLabel={timeLabel[groupLabel]}
              groupBy={groupLabel}
              chartMetric={chartMetric}
              chartType={chartType}
              setChartType={setChartType}
              filterItems={{
                networks: networkOptions,
                applications: applicationOptions,
              }}
            />
          )}
          <div>Processed: {getProcessedTimestamp()}</div>
          <StatsTable
            tableStats={dashboardStats.ChartCommissions}
            isNonTimeGroupedView={groupLabel === 'application' || groupLabel === 'merchant' || groupLabel === 'network'}
            timeLabel={timeLabel[groupLabel]}
            tableLabel={groupLabel}
            chartMetric={chartMetric}
            setChartMetric={setChartMetric}
            chartType={chartType}
            startDate={startDate}
            endDate={endDate}
            getDate={getDate}
            nameFromStorage={nameFromStorage}
          />
        </>
      )}
    </div>
  );
};

export default SalesDashboard;
