import React from 'react';
import Papa from 'papaparse';
import { Button } from 'semantic-ui-react';
import { SalesDashStatsData } from './types';

interface SalesDashTableDownloadProps {
  tableData: SalesDashStatsData[];
  startDate: string;
  endDate: string;
  groupBy: string;
}

export default function StatsTableDownload({ tableData, startDate, endDate, groupBy }: SalesDashTableDownloadProps) {
  const downloadCSV = () => {
    const csv = Papa.unparse(tableData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);

    const fileName = startDate + '-' + endDate + '-stats-by-' + groupBy;
    a.download = fileName;
    a.click();
  };

  return (
    <div className="btnContainerDownload">
      <Button onClick={downloadCSV}>Download Table</Button>
    </div>
  );
}
