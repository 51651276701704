import React, { useState, useEffect } from 'react';
import { orderBy, capitalize } from 'lodash';
import { Table, Loader, Dimmer, Segment } from 'semantic-ui-react';
import { formatToMoney } from './helpers/index';
import StatsTableDownload from './StatsTableDownloadV2';
import StatsTableHeader from './StatsTableHeaderV2';
import { SalesDashStatsData, SalesDashFormState, SalesDashChartMetric, SalesDashTableClickedColumn } from './types';
import { groupByLabel } from '../../pages/SalesDashboardV2/Constants';

interface SalesDashTableProps {
  formState: SalesDashFormState;
  searchURI: string;
  chartMetric: SalesDashChartMetric;
  setChartMetric: (chartMetric: SalesDashChartMetric) => void;
  setError: (error: string) => void;
  getDashboardTableStats: (searchURI: string) => Promise<SalesDashStatsData[]>;
}

const tableHeaders: SalesDashChartMetric[] = [
  {
    key: 'CommissionCount',
    text: 'Commission Count',
    value: 'CommissionCount',
    unit: 'count',
  },
  {
    key: 'PositiveCommissionCount',
    text: 'Positive Commission Count',
    value: 'PositiveCommissionCount',
    unit: 'count',
  },
  {
    key: 'NegativeCommissionCount',
    text: 'Negative Commission Count',
    value: 'NegativeCommissionCount',
    unit: 'count',
  },
  {
    key: 'ZeroCommissionCount',
    text: 'Zero Commission Count',
    value: 'ZeroCommissionCount',
    unit: 'count',
  },
  {
    key: 'GrossSaleAmount',
    text: 'Gross Sales',
    value: 'GrossSaleAmount',
    unit: '$',
  },
  {
    key: 'GrossCommissionAmount',
    text: 'Gross Commissions',
    value: 'GrossCommissionAmount',
    unit: '$',
  },
  {
    key: 'NetSaleAmount',
    text: 'Net Sales',
    value: 'NetSaleAmount',
    unit: '$',
  },
  {
    key: 'NetCommissionAmount',
    text: 'Net Commissions',
    value: 'NetCommissionAmount',
    unit: '$',
  },
  {
    key: 'AvgCommissionRate',
    text: 'Average Commission',
    value: 'AvgCommissionRate',
    unit: '%',
  },
  {
    key: 'ClickCount',
    text: 'Click Count',
    value: 'ClickCount',
    unit: 'count',
  },
  {
    key: 'OrderCount',
    text: 'Order Count',
    value: 'OrderCount',
    unit: 'count',
  },
];

const StatsTable = ({
  formState,
  searchURI,
  chartMetric,
  setChartMetric,
  setError,
  getDashboardTableStats,
}: SalesDashTableProps) => {
  const { startDate, endDate, groupBy } = formState;

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState<SalesDashStatsData[]>([]);
  const [sortBy, setSortBy] = useState(groupBy === 'merchant_id' ? 'GrossCommissionAmount' : 'Date');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const isGroupedByDate = groupBy === 'date';

  const getTableStats = async () => {
    setIsLoading(true);
    try {
      const res = await getDashboardTableStats(searchURI);
      setError('');
      setTableData(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }
  };

  const setSort = (clickedColumn: SalesDashTableClickedColumn) => {
    let newSortOrder: 'asc' | 'desc';
    if (sortBy === clickedColumn) {
      newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
    } else {
      setSortBy(clickedColumn);
      newSortOrder = 'desc';
    }
    setSortOrder(newSortOrder);

    let sortedTable: SalesDashStatsData[] = [];
    switch (clickedColumn) {
      case 'Application ID':
      case 'Merchant ID':
      case 'Network ID':
        sortedTable = orderBy(tableData, ['ID'], [newSortOrder]);
        break;
      case 'Date':
      case 'Name':
        sortedTable = orderBy(tableData, [clickedColumn], [newSortOrder]);
        break;
      default:
        sortedTable = orderBy(tableData, data => Number(data[clickedColumn]), [newSortOrder]);
    }

    setTableData(sortedTable);
    setChartMetric(chartMetric);
  };

  useEffect(() => {
    getTableStats();
  }, [formState]);

  // Drop '_id' from application_id, network_id, merchant_id and capitalize the first letter
  const capitalizedGroupByName = capitalize(groupBy.replace('_id', ''));

  const bodyRows = tableData?.slice(0, 300)?.map(stats => {
    const {
      Date,
      ID,
      Name,
      CommissionCount,
      PositiveCommissionCount,
      NegativeCommissionCount,
      ZeroCommissionCount,
      GrossSaleAmount,
      GrossCommissionAmount,
      NetSaleAmount,
      NetCommissionAmount,
      AvgCommissionRate,
      ClickCount,
      OrderCount,
    } = stats;

    return (
      <Table.Row key={isGroupedByDate ? Date : ID}>
        <Table.Cell className={isGroupedByDate ? 'dateColumn' : ''}>
          <p className="bodyCellLabel">{groupByLabel[groupBy]}: </p>
          {isGroupedByDate ? Date : ID}
        </Table.Cell>
        {!isGroupedByDate && (
          <Table.Cell className="truncate">
            <p className="bodyCellLabel">{capitalizedGroupByName} Name: </p>
            <span title={Name}>{Name}</span>
          </Table.Cell>
        )}
        <Table.Cell>
          <p className="bodyCellLabel">Commission Count: </p>
          {CommissionCount}
        </Table.Cell>
        <Table.Cell>
          <p className="bodyCellLabel">Positive Commission Count: </p>
          {PositiveCommissionCount}
        </Table.Cell>
        <Table.Cell>
          <p className="bodyCellLabel">Negative Commission Count: </p>
          {NegativeCommissionCount}
        </Table.Cell>
        <Table.Cell>
          <p className="bodyCellLabel">Zero Commission Count: </p>
          {ZeroCommissionCount}
        </Table.Cell>
        <Table.Cell>
          <p className="bodyCellLabel">Gross Sales: </p>
          {formatToMoney(GrossSaleAmount)}
        </Table.Cell>
        <Table.Cell>
          <p className="bodyCellLabel">Gross Commissions: </p>
          {formatToMoney(GrossCommissionAmount)}
        </Table.Cell>
        <Table.Cell>
          <p className="bodyCellLabel">Net Sales: </p>
          {formatToMoney(NetSaleAmount)}
        </Table.Cell>
        <Table.Cell>
          <p className="bodyCellLabel">Net Commissions: </p>
          {formatToMoney(NetCommissionAmount)}
        </Table.Cell>
        <Table.Cell>
          <p className="bodyCellLabel">Average Commission: </p>
          {`${AvgCommissionRate}%`}
        </Table.Cell>
        <Table.Cell>
          <p className="bodyCellLabel">Click Count: </p>
          {ClickCount}
        </Table.Cell>
        <Table.Cell>
          <p className="bodyCellLabel">Order Count: </p>
          {OrderCount}
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <>
      {isLoading ? (
        <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        </Segment>
      ) : tableData?.length ? (
        <>
          <StatsTableDownload tableData={tableData} startDate={startDate} endDate={endDate} groupBy={groupBy} />
          <div className="tableContainer">
            <Table celled>
              <StatsTableHeader
                groupByLabel={groupByLabel[groupBy]}
                columns={tableHeaders}
                chartMetric={chartMetric}
                setChartMetric={setChartMetric}
                sortBy={sortBy}
                sortOrder={sortOrder}
                onSort={setSort}
                isGroupedByDate={isGroupedByDate}
              />
              <Table.Body>{bodyRows}</Table.Body>
            </Table>
          </div>
        </>
      ) : (
        <div>There are no stats for the specified parameters.</div>
      )}
    </>
  );
};

export default StatsTable;
