import { connect } from 'react-redux';
import Reconciliation from './Reconciliation';
import { getReconcileJobs, createReconcileJob } from '../../actions/reconciles/reconciles';

const mapActionsToProps = {
  getReconcileJobs,
  createReconcileJob,
};

export default connect(null, mapActionsToProps)(Reconciliation);
