import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Form, Button, Dropdown as DropdownComponent, DropdownProps } from 'semantic-ui-react';
import { request } from 'helpers/api';
import { getDefaultDateInterval } from './helpers/index';
import {
  SalesDashAppGroupFilterOption,
  SalesDashDropdownOption,
  SalesDashFormState,
  SalesDashFilterOption,
  DropdownChangeEvent,
  Merchant,
  SearchResult,
} from './types';
import { groupByOptions, dateIntervalOptions } from '../../pages/SalesDashboardV2/Constants';
import { isValidGroupByCategory, isValidDateTruncCategory } from '../../pages/SalesDashboardV2/helpers';
import Dropdown from '../Dropdown/Dropdown';

interface SalesDashFormProps {
  formState: SalesDashFormState;
  getAppGroupOptions: () => Promise<SalesDashAppGroupFilterOption[]>;
  getFilterOptions: (category: string) => Promise<SalesDashFilterOption[]>;
  setError: (error: string) => void;
  setFormState: (formState: SalesDashFormState) => void;
  getMerchant: (merchantId: number) => Promise<Merchant>;
}

const getOppOptionContent = (option: SalesDashDropdownOption) => (
  option.groupApplications ? (
    <DropdownComponent.Header className="!p-0">{option.text}</DropdownComponent.Header>
  ) : (
    <DropdownComponent.Item className="!pl-6">{option.text}</DropdownComponent.Item>
  )
);

const SalesDashForm = ({ formState, getAppGroupOptions, getFilterOptions, setError, setFormState, getMerchant }: SalesDashFormProps) => {
  const [formFieldState, setFormFieldState] = useState<SalesDashFormState>(formState);
  const [appOptions, setAppOptions] = useState<SalesDashDropdownOption[]>([]);
  const [merchantOptions, setMerchantOptions] = useState<SalesDashDropdownOption[]>([]);
  const [networkOptions, setNetworkOptions] = useState<SalesDashDropdownOption[]>([]);
  const [isMerchantSearchLoading, setIsMerchantSearchLoading] = useState(false);
  const [appSearchQuery, setAppSearchQuery] = useState('');

  const {
    startDate,
    endDate,
    groupBy,
    dateTrunc,
    appsToInclude,
    merchantsToInclude,
    networksToInclude,
    appsToExclude,
    merchantsToExclude,
    networksToExclude,
  } = formFieldState;

  const today = new Date();
  const dateToday = new Intl.DateTimeFormat('fr-CA').format(today);

  const getAppGroupItems = async () => {
    let appGroupOptions: SalesDashAppGroupFilterOption[] = [];
    try {
      appGroupOptions = await getAppGroupOptions();
      setError('');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }

    const dropdownOptions: SalesDashDropdownOption[] = appGroupOptions.flatMap(group => {
      const groupOption = {
        key: group.adminId,
        name: group.adminName,
        text: `${group.adminId} - ${group.adminName}`,
        value: group.adminId,
        groupApplications: group.applications.map(app => app.ID),
      };

      const applicationOptions = group.applications.map(app => ({
        key: app.ID,
        name: app.Name,
        text: ` ${app.ID} - ${app.Name}`,
        value: app.ID,
        groupId: group.adminId,
      }));

      return [groupOption, ...applicationOptions];
    });

    setAppOptions(dropdownOptions)
  };

  const getFilterItems = async (category: string) => {
    let filterItems: SalesDashFilterOption[] = [];
    try {
      filterItems = await getFilterOptions(category);
      setError('');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }

    const dropdownOptions: SalesDashDropdownOption[] = filterItems.map(application => {
      return {
        key: application.ID,
        text: `${application.ID} - ${application.Name}`,
        name: application.Name,
        value: application.ID,
      };
    });

    category === 'network' && setNetworkOptions(dropdownOptions);
  };

  const handleDropdownChange = (e: React.SyntheticEvent<HTMLElement, Event>, { value, name }: DropdownChangeEvent) => {
    setFormFieldState({ ...formFieldState, [name]: value });
  };

  const search = async (query: string) => {
    setIsMerchantSearchLoading(true);
    try {
      const searchResponse = await request('GET', `/api/search?q=${encodeURIComponent(query)}`);
      const searchResults: SearchResult[] = await searchResponse.json();
      const merchantSearchResults = searchResults.filter(result => result.Type === 'merchant');
      const merchantSearchOptions = merchantSearchResults.map(result => ({
        key: result.ID,
        text: `${result.ID} - ${result.Value}`,
        name: result.Value,
        value: result.ID,
      }));
      setMerchantOptions(merchantSearchOptions);
    } catch (err) {
      if (typeof err === 'string') {
        setError(err);
      } else if (err instanceof Error) {
        setError(err.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }
    setIsMerchantSearchLoading(false);
  };

  const debouncedSearch = debounce(search, 300);

  const handleMerchantSearch = (searchQuery: string) => {
    setMerchantOptions([]);
    if (searchQuery.length > 2) debouncedSearch(searchQuery);
  };

  const getMerchantOptionData = async (merchantId: number) => {
    const merchant = await getMerchant(merchantId);
    return {
      key: merchant.ID,
      text: `${merchant.ID} - ${merchant.Name}`,
      name: merchant.Name,
      value: merchant.ID,
    };
  };

  const handleAppFilterChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, name: 'appsToInclude' | 'appsToExclude') => {
    const formFieldListCopy: number[] = [...formFieldState[name]];
    const selectedValue = (e.target as HTMLElement).textContent?.split(' - ')[0] || '';
    const selectedOption = appOptions.find(option => option.value === parseInt(selectedValue));
    const optionDeleted = e.currentTarget.classList.contains('delete');
    const optionCleared = e.currentTarget.classList.contains('clear');

    // Option is selected
    if (selectedOption) {
      const { groupApplications, value } = selectedOption;

      // Selected option is an app group
      if (groupApplications) {
        const newSelectedOptions = [...new Set([...formFieldListCopy, value, ...groupApplications])];
        setFormFieldState({ ...formFieldState, [name]: newSelectedOptions });
        // Selected option is a single app
      } else {
        formFieldListCopy.push(value);
        setFormFieldState({ ...formFieldState, [name]: formFieldListCopy });
      }
      // Option is deleted/cleared
    } else {
      if (optionDeleted) {
        const deletedValue = formFieldListCopy.filter(value => {
          return Array.isArray(data.value) && !data.value.includes(value);
        })[0];
        const deletedOption = appOptions.find(option => option.value === deletedValue);

        // Deleted option is an app group
        if (deletedOption?.groupApplications) {
          const updatedOptions = formFieldListCopy.filter(value => !deletedOption.groupApplications?.includes(value) && deletedValue !== value);
          setFormFieldState({ ...formFieldState, [name]: updatedOptions });
          // Deleted option is a single app
        } else {
          setFormFieldState({ ...formFieldState, [name]: data.value });
        }
      }

      // Option is cleared
      if (optionCleared) {
        setFormFieldState({ ...formFieldState, [name]: data.value });
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Set the date interval to default only if the interval was not changed by the user
    // AND if either new start date or end date was given
    const newState = {
      ...formState,
      startDate: startDate,
      endDate: endDate,
      groupBy: groupBy,
      dateTrunc:
        dateTrunc === formState.dateTrunc && (startDate !== formState.startDate || endDate !== formState.endDate)
          ? getDefaultDateInterval(startDate, endDate)
          : dateTrunc,
      appsToInclude,
      merchantsToInclude,
      networksToInclude,
      appsToExclude,
      merchantsToExclude,
      networksToExclude,
    };
    setFormState(newState);
    setFormFieldState(newState);
  };

  useEffect(() => {
    getFilterItems('merchant');
    getFilterItems('network');
    getAppGroupItems();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Input
          label="Start Date"
          name="startDate"
          type="date"
          min="2017-07-01"
          max={dateToday}
          value={startDate}
          onChange={e => setFormFieldState({ ...formFieldState, startDate: e.target.value })}
        />
        <Form.Input
          label="End Date"
          name="endDate"
          type="date"
          min="2017-07-01"
          max={dateToday}
          value={endDate}
          onChange={e => setFormFieldState({ ...formFieldState, endDate: e.target.value })}
        />
        <Form.Select
          label="Group By"
          name="groupBy"
          value={groupBy}
          options={groupByOptions}
          onChange={(e, { value }) => {
            if (typeof value === 'string') {
              if (isValidGroupByCategory(value)) {
                setFormFieldState({ ...formFieldState, groupBy: value });
              }
            }
          }}
        />
        <Form.Select
          label="Date Interval"
          name="dateTrunc"
          value={dateTrunc}
          options={dateIntervalOptions}
          onChange={(e, { value }) => {
            if (typeof value === 'string') {
              if (isValidDateTruncCategory(value)) {
                setFormFieldState({ ...formFieldState, dateTrunc: value });
              }
            }
          }}
        />
      </Form.Group>
      <div className="categoryFiltersContainer equal width fields">
        <div className="categoryFilters">
          <h3 className="filterLabels">Application</h3>
          <Form.Group>
            <Form.Field>
              <DropdownComponent
                clearable
                name="appsToInclude"
                value={appsToInclude}
                placeholder="Applications to include"
                fluid
                multiple
                selection
                search
                searchQuery={appSearchQuery}
                options={appOptions.filter(option => option.text.toLowerCase().includes(appSearchQuery.toLowerCase())).map(option => ({
                  key: option.value,
                  text: option.text,
                  value: option.value,
                  content: getOppOptionContent(option),
                  style: option.groupApplications ? { fontWeight: 'bold' } : {},
                }))}
                disabled={Boolean(appsToExclude.length)}
                loading={Boolean(!appOptions.length)}
                onSearchChange={(e, { searchQuery }) => setAppSearchQuery(searchQuery)}
                onChange={(e, data) => handleAppFilterChange(e, data, 'appsToInclude')}
              />
            </Form.Field>
            <Form.Field>
              <DropdownComponent
                clearable
                name="appsToExclude"
                value={appsToExclude}
                placeholder="Applications to exclude"
                fluid
                multiple
                selection
                search
                searchQuery={appSearchQuery}
                options={appOptions.filter(option => option.text.toLowerCase().includes(appSearchQuery.toLowerCase())).map(option => ({
                  key: option.value,
                  text: option.text,
                  value: option.value,
                  content: getOppOptionContent(option),
                  style: option.groupApplications ? { fontWeight: 'bold' } : {},
                }))}
                disabled={Boolean(appsToInclude.length)}
                loading={Boolean(!appOptions.length)}
                onSearchChange={(e, { searchQuery }) => setAppSearchQuery(searchQuery)}
                onChange={(e, data) => handleAppFilterChange(e, data, 'appsToExclude')}
              />
            </Form.Field>
          </Form.Group>
        </div>
        <div className="categoryFilters">
          <h3 className="filterLabels">Merchant</h3>
          <Form.Group>
            <Dropdown
              name="merchantsToInclude"
              value={merchantsToInclude}
              placeholder="Merchants to include"
              options={merchantOptions}
              loading={isMerchantSearchLoading}
              disabled={Boolean(merchantsToExclude.length)}
              handleChange={handleDropdownChange}
              handleSearchChange={handleMerchantSearch}
              getOptionData={getMerchantOptionData}
            />
            <Dropdown
              name="merchantsToExclude"
              value={merchantsToExclude}
              placeholder="Merchants to exclude"
              options={merchantOptions}
              loading={isMerchantSearchLoading}
              disabled={Boolean(merchantsToInclude.length)}
              handleChange={handleDropdownChange}
              handleSearchChange={handleMerchantSearch}
              getOptionData={getMerchantOptionData}
            />
          </Form.Group>
        </div>
        <div className="categoryFilters">
          <h3 className="filterLabels">Network</h3>
          <Form.Group>
            <Dropdown
              name="networksToInclude"
              value={networksToInclude}
              placeholder="Networks to include"
              options={networkOptions}
              disabled={Boolean(networksToExclude.length)}
              loading={Boolean(!networkOptions.length)}
              handleChange={handleDropdownChange}
            />
            <Dropdown
              name="networksToExclude"
              value={networksToExclude}
              placeholder="Networks to exclude"
              options={networkOptions}
              disabled={Boolean(networksToInclude.length)}
              loading={Boolean(!networkOptions.length)}
              handleChange={handleDropdownChange}
            />
          </Form.Group>
        </div>
      </div>
      <div className="submitBtnContainer">
        <Button type="submit" id="submitBtn">
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default SalesDashForm;
