import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { SalesDashChartMetric, SalesDashStatsData, SalesDashTableClickedColumn } from './types';

interface SalesDashTableHeaderProps {
  columns: SalesDashChartMetric[];
  sortBy: string;
  sortOrder: string;
  onSort: (clickedColumn: SalesDashTableClickedColumn) => void;
  chartMetric: SalesDashChartMetric;
  setChartMetric: (chartMetric: SalesDashChartMetric) => void;
  groupByLabel: string;
  isGroupedByDate: boolean;
}

const StatsTableHeader = ({
  columns,
  sortBy,
  sortOrder,
  onSort,
  chartMetric,
  setChartMetric,
  groupByLabel,
  isGroupedByDate,
}: SalesDashTableHeaderProps) => {
  const groupByLabelHeader: SalesDashChartMetric = {
    key: groupByLabel as keyof SalesDashStatsData,
    text: groupByLabel,
    value: 'groupByLabel',
    unit: 'groupByLabel',
  };
  const nameHeader = {
    key: 'Name' as keyof SalesDashStatsData,
    text: 'Name',
    value: 'name',
    unit: 'name',
  };

  const renderContent = (column: SalesDashChartMetric) => {
    const isActive: boolean = sortBy === column.key;

    return (
      <Table.HeaderCell key={column.key} className="headerCell" direction={isActive ? sortOrder : 'asc'}>
        <div
          className={isActive ? 'headerCellLabel  active' : 'headerCellLabel'}
          onClick={() => {
            onSort(column.key);
          }}
        >
          <span>
            {column.text}
            <Icon className={isActive ? (sortOrder === 'asc' ? 'sort up' : 'sort down') : 'sort'} />
          </span>
        </div>
      </Table.HeaderCell>
    );
  };

  const renderColumns = () => columns.map(column => renderContent(column));

  // Render chart icon for all columns, except for Date and ClickCount
  const renderIcons = () => {
    const columnsCopy = [...columns];
    if (isGroupedByDate) {
      columnsCopy.unshift(groupByLabelHeader);
    } else {
      columnsCopy.unshift(groupByLabelHeader, nameHeader);
    }
    return columnsCopy.map(column => {
      /** Render empty cell without the chartIcon for columns that won't affect chart **/
      /** (Date, Application ID, Merchant ID, Network ID, Name) **/
      return column.key === 'Date' || column.value === 'groupByLabel' || column.value === 'name' ? (
        <Table.HeaderCell key={column.key} className="headerCell"></Table.HeaderCell>
      ) : (
        <Table.HeaderCell
          key={column.key}
          className="headerCell chartIcon"
          onClick={() => {
            setChartMetric(column);
          }}
        >
          <span className="iconCell">
            <Icon
              className={chartMetric?.key === column.key ? 'inverted' : ''}
              circular
              name="area graph"
              color="orange"
              size="small"
            />
          </span>
        </Table.HeaderCell>
      );
    });
  };

  return (
    <Table.Header id="tableHeader">
      <Table.Row>
        {/* To prevent from getting a react error for lacking a key */}
        {[renderContent(groupByLabelHeader), !isGroupedByDate && renderContent(nameHeader), renderColumns()]}
      </Table.Row>
      <Table.Row>{renderIcons()}</Table.Row>
    </Table.Header>
  );
};

export default StatsTableHeader;
