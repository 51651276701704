import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import { Button, Table, Dimmer, Loader, Segment } from 'semantic-ui-react';
import { getNetworkName, toPrettyAmount, getEmptyDropdown } from './helpers';
import PaginateControl from './PaginateControl';
import { toPrettyTime } from '../../helpers';

const PAGE_SIZE = 25;

const StatusColumn = ({ Value }) => {
  if (!Value) {
    return '0 items';
  }

  const { Count, TotalSales, TotalCommissions } = Value;
  const itemWord = Count === 1 ? 'item' : 'items';
  return (
    <>
      <span>
        {Count} {itemWord}
      </span>
      <br />
      <span>TotalSales: {TotalSales}</span>
      <br />
      <span>TotalCommissions: {TotalCommissions}</span>
    </>
  );
};

const ReconciliationJob = ({ id, getReconcileJob, getReconcileJobMerchants }) => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rows, setRows] = useState([]);
  const [job, setJob] = useState({});
  const [merchantOptions, setMerchantOptions] = useState([getEmptyDropdown()]);

  const statusOptions = [
    {
      key: 'all',
      text: 'All',
      value: 'all',
    },
    {
      key: 'success',
      text: 'Success',
      value: 'success',
    },
    {
      key: 'failed',
      text: 'Failed',
      value: 'failed',
    },
    {
      key: 'started',
      text: 'Started',
      value: 'started',
    },
  ];

  useEffect(() => {
    const fetchJobData = async () => {
      setErrorMessage('');
      setIsLoading(true);
      try {
        const statusForRequest = status === 'all' ? null : status;
        const { Job, Merchants } = await getReconcileJob({ id, page, page_size: PAGE_SIZE, status: statusForRequest });
        setJob(Job);
        setRows(Merchants || []);
      } catch (e) {
        console.log(e);
        setErrorMessage(e.error?.ErrorMessage || e.message || 'An unknown error has occurred while fetching jobs.');
      }
      setIsLoading(false);
    };
    fetchJobData();
  }, [page, status, id]);

  useEffect(() => {
    const fetchJobMerchants = async () => {
      try {
        const merchants = await getReconcileJobMerchants({ id });
        const options = merchants.map(({ Name, XID }) => ({
          key: XID,
          text: Name,
          value: XID,
        }));
        setMerchantOptions([getEmptyDropdown(), ...options]);
      } catch (e) {
        console.log(e);
      }
    };
    fetchJobMerchants();
  }, [id]);

  const handleStatusChange = (e, { value }) => {
    setStatus(value);
    setPage(1);
  };

  const handleMerchantChange = (e, { value }) => {
    history.push(`/reconciliation/${id}/merchant/${value}`);
  };

  const goToJobItems = mid => {
    history.push(`/reconciliation/${id}/merchant/${mid}`);
  };

  if (errorMessage) {
    return (
      <div>
        <p>{errorMessage}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <h3>{getNetworkName(job.NetworkId)}</h3>
        <div>
          <b>{toPrettyTime(job.Date)}</b>
        </div>
        <div>
          <b>{toPrettyAmount(job.Amount, job.Currency, job.BankAmount)}</b>
        </div>
        <div>
          <b>Items:</b>
          {job.NumItems}
        </div>
        <div>
          <b>Status:</b> {job.Status}
        </div>
        <div>
          <b>Retries: </b>
          {job.RetryCount}
        </div>
      </div>
      <hr />
      <Form className="get-reconcile-job" autoComplete="off">
        <Form.Group style={{ justifyContent: 'space-between' }}>
          <Form.Dropdown
            label="Job Merchants"
            name="merchants"
            options={merchantOptions}
            onChange={handleMerchantChange}
            search
            selection
            placeholder="Select Merchant"
          />
          <Form.Dropdown
            label="Status"
            name="status"
            options={statusOptions}
            onChange={handleStatusChange}
            value={status}
            selection
          />
          <PaginateControl
            page={page}
            prevDisabled={page === 1 || isLoading}
            nextDisabled={isLoading}
            onPrev={() => setPage(pageNo => pageNo - 1)}
            onNext={() => setPage(pageNo => pageNo + 1)}
          />
        </Form.Group>
      </Form>
      <Table>
        <thead style={{ fontWeight: 'bold', fontSize: 18, backgroundColor: '#e8e5e3' }}>
          <tr>
            <th>Merchant</th>
            <th>Started</th>
            <th>Success</th>
            <th>Failed</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map(({ MerchantName, MerchantXID, Data }) => {
            return (
              <tr key={MerchantXID}>
                <td title={MerchantName}>{MerchantName}</td>
                <td>
                  <StatusColumn Value={Data.Started} />
                </td>
                <td>
                  <StatusColumn Value={Data.Success} />
                </td>
                <td>
                  <StatusColumn Value={Data.Failed} />
                </td>
                <td>
                  <Button
                    onClick={() => {
                      goToJobItems(MerchantXID);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    View Line Items
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default ReconciliationJob;
