import { NetworksData } from 'constants/networks';
import { read, utils } from 'xlsx';

export const convertExcelFileToCSV = file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    let result = {};
    reader.onload = e => {
      const data = e.target.result;
      const workbook = read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const csvString = utils.sheet_to_csv(workbook.Sheets[sheetName]);
      result = new File([csvString], 'reconcile.csv', {
        type: 'text/csv',
      });
      resolve(result);
    };
    reader.readAsBinaryString(file);
  });
};

export const getNetworkDropdownOptions = () => {
  return Object.entries(NetworksData).map(([networkName, networkData]) => ({
    key: networkName,
    value: networkName,
    text: `${networkData.id} - ${networkName}`,
  }));
};

export const getNetworkName = networkID => {
  const found = Object.entries(NetworksData).find(([, networkData]) => networkData.id === networkID);
  if (!found) return null;
  return found[0];
};

export const toPrettyAmount = (Amount, Currency, BankAmount) => {
  let money = '';
  if (Currency === 'USD') {
    money = '$' + Amount;
  } else {
    money = `${Currency}: ${Amount} USD: ${BankAmount}`;
  }
  return money;
};

export const getEmptyDropdown = () => {
  return {
    key: '',
    value: '',
    text: '',
  };
};
